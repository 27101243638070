<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';

const store = useStore();
const $q = useQuasar();

const displayParallax = computed(() => $q.screen.width >= store.state.parallaxBreakpoint);
const largeParallax = computed(() => $q.screen.width >= 900);

</script>

<template lang="html">
  <div class="home-parallax">
    <q-parallax
      v-if="largeParallax"
      src="@/assets/images/parallax.jpg"
    >
      <div class="logo-container">
        <router-link to="/">
          <img alt="" src="@/assets/images/logo.png" class="logo" />
        </router-link>
      </div>
    </q-parallax>
    <q-parallax
      v-else-if="displayParallax"
      src="@/assets/images/parallax_small.jpg"
      :height="300"
    >
      <div class="logo-container">
        <router-link to="/">
          <img alt="" src="@/assets/images/logo.png" class="logo" />
        </router-link>
      </div>
    </q-parallax>
  </div>
</template>

<style lang="scss">
@import '~quasar-variables';

.home-parallax { position: relative; }
.logo-container {
  display: block;
  width: 100%; height: 100%;
  max-width: $page-max-width;
  padding: 30px 0 0 15px;
  .logo { max-width: 350px; height: auto; filter: drop-shadow(10px 10px 5px $blue-grey-2); }
}
</style>
