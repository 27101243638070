<script setup>
import { computed } from 'vue';
import LoadingView from '@/components/layouts/loading-view.vue';

const props = defineProps({
  loading: { type: Boolean, default: false },
  sidebarRight: { type: Boolean, default: false },
  sidebarLeft: { type: Boolean, default: false },
  loadSettings: { type: Object, default: () => ({}) },
});

const layoutGrid = computed(() => {
  const gridClass = {};
  if (props.sidebarLeft && props.sidebarRight) gridClass['both-sdb'] = true;
  else if (props.sidebarLeft && !props.sidebarRight) gridClass['leftonly-sdb'] = true;
  else if (!props.sidebarLeft && props.sidebarRight) gridClass['rightonly-sdb'] = true;
  return gridClass;
});
</script>

<template>
  <q-page-container class="page-layout-container">
    <q-page class="page-layout q-pa-sm">
      <transition-group
        name="components"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated is-leaving animate__fadeOut"
      >
        <LoadingView
          v-if="loading"
          key="loading"
          :args="loadSettings"
        />
        <div
          v-else
          key="page-content"
          class="layout-container"
          :class="layoutGrid"
        >
          <div
            v-if="sidebarLeft"
            class="sidebar flex justify-center"
          >
            <slot name="leftSidebar">Here goes the left sidebar</slot>
          </div>
          <div class="main-content">
            <slot name="mainContent">Here goes the main content</slot>
          </div>
          <div
            v-if="sidebarRight"
            class="sidebar flex justify-center"
          >
            <slot name="rightSidebar">Here goes the right sidebar</slot>
          </div>
        </div>
      </transition-group>
    </q-page>
  </q-page-container>
</template>

<style lang="scss">
@import '~quasar-variables';

$grid-breakpoint: 900px;

.page-layout {
  max-width: $page-max-width;
  margin: auto;
  .layout-container {
    .main-content { position: relative; }
    @media (min-width: $grid-breakpoint) {
      &.leftonly-sdb, &.rightonly-sdb, &.both-sdb { display: grid; }
      &.leftonly-sdb { grid-template-columns: 1fr 3fr; }
      &.rightonly-sdb { grid-template-columns: 3fr 1fr; }
      &.both-sdb { grid-template-columns: 1fr 2fr 1fr; }
    }
    @media (max-width: $grid-breakpoint - 1) {
      .sidebar { display: none };
    }
  }
}
</style>
