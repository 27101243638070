<script setup>
/* eslint import/no-unresolved: */
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useQuasar } from 'quasar';
import PageLayout from '@/components/layouts/page-layout';
import SiteHeader from '@/components/widgets/site-header';
import HomeParallax from './home-parallax';

const store = useStore();
const $q = useQuasar();

const smallScreen = computed(() => $q.screen.width < store.state.parallaxBreakpoint);
const brandsLabel = computed(() => store.state.labels.home_brands);
const categoriesLabel = computed(() => store.state.labels.home_categories);
const brands = computed(() => store.state.brands);
const categories = computed(() => store.state.mainMenu);
</script>

<template lang="html">
  <SiteHeader v-if="smallScreen" hide-menu />
  <HomeParallax v-else />
  <PageLayout>
    <template #mainContent>
      <div class="home-content">
        <section class="catalog-brands q-pa-md">
          <div class="section-title">{{ brandsLabel }}</div>
          <div class="brands-container row justify-around q-pa-md">
            <div
              v-for="(brand, index) in brands"
              :key="index"
              class="home-brand q-pa-sm"
            >
              <router-link :to="`/catalog/brand/${brand.link}`">
                <img
                  alt=""
                  :src="brand.img"
                  class="brand-img"
                />
              </router-link>
            </div>
          </div>
        </section>
        <q-separator />
        <section class="catalog-categories q-pa-md q-mt-md">
          <div class="section-title q-mb-md">{{ categoriesLabel }}</div>
          <q-list class="categories-container row justify-around q-pa-md">
            <q-item
              v-for="(category, index) in categories"
              :key="index"
              :to="`${category.path}/${category.link}`"
              class="category-item"
            >
              <q-item-section avatar>
                <q-icon name="double_arrow" color="blue-grey-3"/>
              </q-item-section>
              <q-item-section
                class="category-label"
                :class="{ 'red-label': index % 2 }"
              >
                {{ category.label.toUpperCase() }}
              </q-item-section>
            </q-item>
            <div
              v-for="n in 3"
              :key="n"
              class="category-item"
            ></div>
          </q-list>
        </section>
      </div>
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import '~quasar-variables';

.home-content {
  .section-title {
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 1px;
    color: $blue-grey-8;
  }
  .brands-container .home-brand {
    max-width: 300px;
    .brand-img { width: 100%; height: auto; }
  }
  .categories-container .category-item {
    // text-align: center;
    min-width: 300px;
  }
  .categories-container .category-label {
    font-size: 1.2rem;
    // font-weight: bold;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px black;
    // color: black;
    &.red-label { color: $logo-red; text-shadow: 1px 1px 1px $logo-red; }
  }
}
</style>
