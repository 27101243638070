import script from "./page-layout.vue?vue&type=script&setup=true&lang=js"
export * from "./page-layout.vue?vue&type=script&setup=true&lang=js"

import "./page-layout.vue?vue&type=style&index=0&id=1e6c94ba&lang=scss"

const __exports__ = script;

export default __exports__
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPageContainer,QPage});
