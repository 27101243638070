<script setup>
/* eslint no-unused-vars: */
import eventHub from '@/config/event-hub';

const props = defineProps({
  hideMenu: {
    type: Boolean,
    default: false,
  },
});
</script>

<template lang="html">
  <q-header class="header-layout">
    <q-toolbar class="site-toolbar q-pa-sm">
      <router-link to="/">
        <img alt="" src="@/assets/images/logo.png" class="logo" />
      </router-link>
      <q-toolbar-title />
      <q-btn
        v-if="!hideMenu"
        icon="menu"
        text-color="blue-grey-8"
        flat
        size="1.3rem"
        @click="eventHub.$emit('open-menu')"
      />
    </q-toolbar>
  </q-header>
</template>

<style lang="scss">
@import '~quasar-variables';

.header-layout {
  // background-color: transparent;
  background-color: rgba(white, .6);
  .site-toolbar {
    max-width: $page-max-width;
    margin: auto; // 30px 10px 4px $blue-grey-2
    .logo {
      height: auto;
      filter: drop-shadow(10px 5px 10px $blue-grey-2);
      max-width: 250px;
      @media (min-width: 450px) { max-width: 350px; }
    }
  }
}
</style>
